'use client'
import React, {
	ReactNode,
	useContext,
	useEffect,
	useState,
	createContext,
	useMemo,
} from 'react'
import { DocumentReference, doc, onSnapshot } from 'firebase/firestore'
import { firestore } from '@/config/DBConnection'

interface ICall {
	activeUid: string
	assignationPath: string
	assignation_id: string
	calling: boolean
	cuit: string
	dependant: false | string
	requested?: boolean
	room: string
	token: string
	type: string
}

interface ICallContext {
	call: ICall | null
	disableCalling: () => void
}

interface IProviderCall {
	uid: string | undefined
	children: ReactNode
}

export const callContext = createContext<ICallContext>({
	call: null,
	disableCalling: () => {},
})

export const useCallContext = () => {
	return useContext(callContext)
}

function ProviderCall({ uid, children }: IProviderCall) {
	const [call, setCall] = useState<ICall | null>(null)

	useEffect(() => {
		if (uid) {
			const activeCallRef = doc(
				firestore,
				`user/${uid}/activeServices/onlineCall`,
			) as DocumentReference<ICall>

			const subscription = onSnapshot(activeCallRef, (callQuery) => {
				const callData = callQuery.data()
				if (callData) {
					setCall({
						...callData,
					})
				}
			})

			return () => {
				if (typeof subscription === 'function') {
					subscription()
				}
			}
		}
	}, [onSnapshot, uid, firestore])

	const disableCalling = () => {
		setCall({
			...call,
			calling: false,
		} as ICall)
	}

	const value = useMemo(
		() => ({
			call,
			disableCalling,
		}),
		[call],
	)

	return (
		<callContext.Provider value={value}>
			{children}
		</callContext.Provider>
	)}

export default ProviderCall
