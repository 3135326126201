'use client'
import { ErrorBoundary } from 'react-error-boundary'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { ErrorFallback, errorHandler } from '@/config/ErrorBoundary'
import store from '@/store/configStore'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactNode, useEffect, useState } from 'react'
import {
	QueryClient as QueryClientNew,
	QueryClientProvider as QueryClientProviderNew,
} from '@tanstack/react-query'
import { init } from '@amplitude/analytics-browser'
import ProviderClient from '@/OldRouter/Providers/ProviderClient'
import ProviderCall from '@/OldRouter/Providers/ProviderCall'
import ProviderChatAtt from '@/OldRouter/Providers/ProviderChatAtt'
import { IPatient } from '@umahealth/entities'

const errorService = (error: Error, info: { componentStack: string }) => {
	console.error(error)
	if (errorHandler) errorHandler.report(JSON.stringify(info))
}

export function BeforeRouter({ children, patient, uid}: { children: ReactNode, patient:IPatient|null, uid: string|undefined }) {
	const [queryClientOld] = useState(() => new QueryClient())
	const [queryClientNew] = useState(() => new QueryClientNew())

	useEffect(() => {
		/* Esto asegura que amplitude sólo se inicie en prod, esta inicialización es sólo para client-side */
		if (process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID === 'uma-v2') {
			init(process.env.NEXT_PUBLIC_AMPLITUDE_APIKEY, uid)
		}
	}, [])
	
	return (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onError={(error, errorInfo) => errorService(error, errorInfo)}
			onReset={() => {
				console.log('Reintentar')
			}}
		>
			<Provider store={store}>
				<QueryClientProvider client={queryClientOld}>
					<QueryClientProviderNew client={queryClientNew}>
						<ProviderClient patient={patient}>
							<ProviderCall uid={patient?.id}>
								<ProviderChatAtt uid={patient?.id}>
									{children}
								</ProviderChatAtt>
							</ProviderCall>
						</ProviderClient>
					</QueryClientProviderNew>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</Provider>
		</ErrorBoundary>
	)
}
